/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import "reset.css"
import "../styles/main.scss"

import Header from "./header"
import ImageNight from "./image-night"


const Layout = ({ title, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      wp {
          allSettings {
              generalSettingsTitle
              generalSettingsDescription
          }
      }
      pageLegal:wpPage(slug: {eq: "politique-de-confidentialite"}) {
          uri
          title
      }
      pageAbout:wpPage(slug: {eq: "qui-sommes-nous"}) {
          uri
          title
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.wp.allSettings.generalSettingsTitle}
              siteSubtitle={data.wp.allSettings.generalSettingsDescription} />
      <div className={`site`}>
        <main>{children}</main>
      </div>
      <footer>
        <ImageNight />
        <div className={`footer__wrapper`}>
          {data.site.siteMetadata?.title} © {new Date().getFullYear()}
          <nav>
            <Link to={data.pageAbout.uri}>{data.pageAbout.title}</Link>
            {' '}
            <Link to={data.pageLegal.uri}>{data.pageLegal.title}</Link>
          </nav>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
